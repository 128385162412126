<template>
  <div class="post-section">
    <div class="cntr cntr-wide">
      <h1>Dodawanie postu</h1>
      <button class="btn btn-primary" @click="addPost">Dodaj post</button>
      <div class="message message-errors" v-if="messages.length > 0">
        <ul>
          <li v-for="(message, index) in messages" :key="index">
            {{ message }}
          </li>
        </ul>
      </div>
      <PostForm :post="post" :busy="loading" ref="form"/>
    </div>
  </div>
</template>

<script>
import PostForm from '@/components/PostForm';
import { getOne, create } from "../core/requests/authed-request";

export default {
  name: "AddPost",
  props: ['id'],
  components: {
    PostForm
  },
  data() {
    return {
      post: {},
      // editor: null,
      busy: false,
      loading: false,
      messages: []
    };
  },
  methods: {
    getPostData() {
      this.loading = true;
      getOne(this.id).then(
        resp => {
          this.post = resp.data;
          this.post.id = null;
          this.post.title = '';
          this.post.slug = '';
          const data = typeof this.post.contentData !== 'undefined' && this.post.contentData != '' ? JSON.parse(this.post.contentData) : [];
          this.$refs.form.editorInit(data);
          this.loading = false;
        }
      ).catch(e => {
        this.loading = false;
        console.error(e);
      })
    },
    addPost() {
      if (!this.busy) {
        this.busy = true;
        // debugger;
        this.$refs.form.retriveData().then(
          post => {
            create(post).then((resp) => {
              this.busy = false;
              if (resp.statusCode == 201) {
                console.log("NOTE: Go to posts view.");
                this.$router.replace({
                  name: 'Posts'
                })
              }
            }).catch(error => {
              this.busy = false;
              this.messages = error;
            });
          }
        ).catch(e => {
          this.busy = false;
          console.error(e);
        });
      }
    }
  },
  created() {
  },
  mounted() {
    console.log(this.id);
    if (this.id) {
      this.getPostData();
    } else {
      this.$refs.form.editorInit();
    }
  },
  destroyed() {
  },
};
</script>

<style scoped lang="scss">
</style>